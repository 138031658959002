// colors
$color-red-primary: #E43F40;
$color-red-secondary: #C74242;
$color-red-tertiary: #FF0000;
$color-black-primary: #1C1E1F;
$color-black-secondary: #000000;
$color-gray-primary: #5B5B5B;
$color-blue-promo: #2369FA;

// media queries
$breakpoint-desktop-lg: 1200px;
$breakpoint-desktop: 768px;
$breakpoint-desktop-md: 600px;
$breakpoint-desktop-sm: 480px;

// mixins
@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin breakpointLg {
  @media (min-width: $breakpoint-desktop-lg) {
    @content;
  }
}

@mixin breakpointMd {
  @media (min-width: $breakpoint-desktop-md) {
    @content;
  }
}

@mixin breakpointSm {
  @media (min-width: $breakpoint-desktop-sm) {
    @content;
  }
}